import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import HVCHomePage from './components/HVCHomePage';
import { ChooseImage } from './components/ChooseImage';
import ConfigImageSelection from './components/ConfigImageSelection';
import PricingCard from './components/PricingCard';
import ConfigSelection from './components/ConfigSelection';
import ChooseConfig from './components/ChooseConfig';
import StorageAndNetwork from './components/StorageAndNetwork';
import ChooseSecurity from './components/ConfigureSecurity';

function App() {
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState("");
  const [config, setConfig] = useState("");
  const [configType, setConfigType] = useState({});
  console.log("configType", configType);
  return (
    <React.Fragment>
      <div className="text-center shadow-lg p-2 mb-4 bg-white rounded ">
        <h2>HVC
        </h2>
      </div>

      <div className='row col-12 p-2 mb-3 text-sm-left'>
        <div className='col-8 text-sm-left'>
          <ChooseImage />
          <br />
          <br />
          <br />
          <br />
          <div>
            
            <ConfigSelection setCurrentPage={setCurrentPage}/>
              {/* <ChooseInstance /> */}
          </div>
        </div>

        <div className='row col-4 p-2 mb-3 float-right'>
          <PricingCard selected={selected} configType={configType} />
        </div>
        <div className="col-8 float-left" >
        {currentPage === 'chooseImage' &&
          <ConfigImageSelection selected={selected} setSelected={setSelected} /> }
          {currentPage === 'chooseInstanceType' &&
          <ChooseConfig config={config}  setConfig={setConfig} configType ={configType} setConfigType={setConfigType} /> }
        </div >
        <div className="col-8 float-left">
        {currentPage === 'chooseStorageAndNetwork' &&
        <StorageAndNetwork/>}
        </div>
<div className="col-8 float-left">
{currentPage === 'configAndSecurity' &&
  <ChooseSecurity/>}
</div>

      </div>

    </React.Fragment>
  );
}

export default App;
