import { useState } from "react";


const RulesAndProtocols = () => {

    const [type, setType] = useState("")
    const onchange = (e: any) => {
        console.log(e);
        setType(e)
    }

    return <div className="card shadow-lg m-4">
    <div className="card-body" style={{fontSize:"12px"}}>
        <div className="row col-12">

            <div className="col-2">
                <div className="text-center">
                    <label >Type</label>
                </div>
                <div className="form-group mt-2">
                    <select className="form-control" id="dropdown" onChange={onchange}>
                        <option>HTTPS</option>
                        <option>SSH</option>
                        <option>SMTP</option>
                    </select>
                </div>

            </div>
            <div className="col-2">
            <div className="text-center">
                    <label >Protocol</label>
                </div>
                <div className="mt-2 ">
                    <input className="form-control" type="text" />
                </div>
            </div>
            <div className="col-2">
                <div className="text-center">
                    <label >Port Range</label>
                </div>
                <div className="mt-2 ">
                    <input className="form-control" type="text" />
                </div>
            </div>
            <div className="col-2 " style={{ fontSize: "14px" }} >
                <div className="text-center">
                    <label >Source</label>
                </div>
                <div className="mt-2 ">
                    <input className="form-control" type="text" />
                </div>
            </div>
            <div className="col-2 " style={{ fontSize: "14px" }} >
                <div className="text-center">
                    <label >Description</label>
                </div>
                <div className="mt-2 ">
                    <input className="form-control" type="text" />
                </div>
            </div>

        </div>
    </div>
</div>
}

export default RulesAndProtocols;