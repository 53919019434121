import React, { useState } from "react";

import cpumemory from "../data/cpumemory.json"

const ChooseConfig = (props: any) => {
  const { setConfig, config , configType, setConfigType} = props;

  let cpumemory1 = JSON.parse(JSON.stringify(cpumemory))
  


  console.log("cpumemory", cpumemory)
  function onclick(configs: String) {
    setConfig(configs)
  }

  return (
    <React.Fragment>
      <div>
        <div className="float-start shadow-lg m-2" aria-label="Basic example">
          <button type="button" className="btn btn-outline-primary" style={{ fontSize: "1rem" }} onClick={() => onclick("generalPurpose")} >General Purpose</button>
        </div>

        <div className="float-start shadow-lg m-2" aria-label="Basic example">
          <button type="button" className="btn btn-outline-primary" style={{ fontSize: "1rem" }} onClick={() => onclick("cpuOptimised")} >CPU Optimised</button>
        </div>

        <div className="float-start shadow-lg m-2">
          <button type="button" className="btn btn-outline-primary" style={{ fontSize: "1rem" }} onClick={() => onclick("storegeOptimised")} >Storage Optimised</button>
        </div>

        <div className="float-start shadow-lg m-2 ">
          <button type="button" className="btn btn-outline-primary" style={{ fontSize: "1rem" }} onClick={() => onclick("networkOptimised")} >Network Optimised</button>
        </div>

      </div>
      <div>
        <div className="row col-12 ml-2 p-4">
          <p className="ml-2" style={{ fontSize: "1.2rem" }}>Create Configuration</p>
          <div className="col-4 ml-2 mt-2"><div className="dropdown col-xs-12 col-sm-6 col-md-4 col-lg-4 text-dark">
            <button className="btn btn-outline-primary dropdown-toggle squared shadow-lg" type="button" data-bs-toggle="dropdown" >
              CPU Cores
            </button>
            <ul className="dropdown-menu row col-12 squared shadow-lg">
              {cpumemory1[config] && Object.keys(cpumemory1[config]["CPU"]).
                map((ele) => <li><a className="dropdown-item" href="#" 
                onClick={() => setConfigType({...configType, [ele]: cpumemory1[config]["CPU"][ele] })}>{ele}</a></li>)}

            </ul>
          </div></div>
          <div className="col-4 ml-2 mt-2 "><div className="dropdown col-xs-12 col-sm-6 col-md-4 col-lg-4 text-dark">
            <button className="btn btn-outline-primary dropdown-toggle squared shadow-lg" type="button" data-bs-toggle="dropdown">
              Memory
            </button>
            <ul className="dropdown-menu row col-12 squared shadow-lg">
              {cpumemory1[config] && Object.keys(cpumemory1[config]["storage"])
                .map((ele) => <li><a className="dropdown-item" href="#" 
                onClick={() => setConfigType({...configType, [ele]: cpumemory1[config]["storage"][ele] })}>{ele}</a></li>)}
            </ul>
          </div></div>


        </div>
      </div>
    </React.Fragment>
  )

}
export default ChooseConfig;
