import React, { useState } from "react";

import imageconfigs from "../data/imageconfigs.json"



const ConfigImageSelection = (props: any) => {
    const {selected, setSelected} = props;
    const [selectedRadio, setSelectedRadio] = useState("");
    const [selectedConfig, setSelectedConfig] = useState("");
    console.log(imageconfigs,selectedRadio)
    const onClick = (obj: any) => {
      
        setSelected([ {...obj, config: obj.configTypes.length ===1? obj.configTypes[0]: selectedConfig}])
    }
    return (
        <div>
        {imageconfigs.map(ele => <div key={ele.name} className="card shadow-lg m-4">
            <div className="card-body">
                <div className="row col-12">
                    <div className="col-2">
                    <img src={ele.imgUrl} className="img-thumbnail"/>              </div>     
                    <div className="col-8">
                        <h5>
                            {ele.name}
                        </h5>
                        <p style={{ fontSize: "12px" }}>
                            {ele.description}
                        </p>
                    </div>
                    <div className="col-2 "style={{ fontSize: "14px" }} key={ele.name} >
                        {ele.configTypes.map((con) => <div className="form-check">
                        {ele.configTypes.length >1 && 
                            <input className="form-check-input" type="radio" name={`${ele.name}`} id={`${ele.name}1`} 
                            onClick={() => {
                                setSelectedRadio(ele.name);
                                setSelectedConfig(con)
                                }} />}
                            <label className="form-check-label" >
                                {con}
                            </label>
                        </div>
                        )}
                        <button disabled={selectedRadio !== ele.name && ele.configTypes.length >1 } className="btn btn-primary btn-block squared btn-md" onClick={()=>onClick(ele)}>
                            Select
                        </button>
                    </div>

                </div>
            </div>
        </div>)}
        </div>
    )
}


export default ConfigImageSelection;