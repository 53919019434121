import React from "react";



const ConfigSelection=(props: any)=> {
  const {setCurrentPage} = props;

    function onclick(page: String) {
      setCurrentPage(page)
    }

    return (
      <React.Fragment>
        <div>
          <div className="btn-primary float-start shadow-lg m-2" aria-label="Basic example">
            <button type="button" className="btn" style={{ fontSize: "0.75rem" }} onClick={()=> onclick("chooseImage")} >1. Choose Image</button>
          </div>

          <div className="btn-primary float-start shadow-lg m-2" aria-label="Basic example">
            <button type="button" className="btn" style={{ fontSize: "0.75rem" }} onClick={()=> onclick("chooseInstanceType")} >2. Choose Instance Type</button>
          </div>

          <div className="btn-primary float-start shadow-lg m-2 h7">
            <button type="button" className="btn" style={{ fontSize: "0.75rem" }} onClick={()=> onclick("chooseStorageAndNetwork")} >3. Choose Storage and Network</button>
          </div>

          <div className="btn-primary float-start shadow-lg m-2 btn-font-size-sm" aria-label="Basic example">
            <button type="button" className="btn" style={{ fontSize: "0.75rem" }}  onClick={()=> onclick("configAndSecurity")} >4. Config and Security</button>
          </div>

          <div className="btn-primary float-start shadow-lg m-2" aria-label="Basic example">
            <button type="button" className="btn" style={{ fontSize: "0.75rem" }} onClick={()=> onclick("reviewAndLaunch")} >5. Review and Launch</button>
          </div>
        </div>
      </React.Fragment>
    )
  
}
export default ConfigSelection;
