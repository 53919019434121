import React, { useState } from "react";
import StorageNet from "./StorageNet";




const StorageAndNetwork = () => {
    const result : string[] = [];
    const [count,setCount] = useState(result);


   
    function add () {
        return <div className="card shadow-lg m-4">
            <div className="card-body" style={{fontSize:"12px"}}>
                <div className="row col-12">

                    <div className="col-2">
                        <div className="text-center">
                            <label >Type</label>
                        </div>
                        <div className="form-group mt-2">
                            <select className="form-control" id="dropdown">
                                <option>Magnetic</option>
                                <option>SSD</option>
                            </select>
                        </div>

                    </div>
                    <div className="col-1">
                        <div className="text-center">
                            <label >Volume</label>
                        </div>
                        <div className="mt-4 text-center">
                            <label>Ext</label>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="text-center">
                            <label >Capacity(GB)</label>
                        </div>
                        <div className="mt-2 ">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="text-center">
                            <label >Encryption</label>
                        </div>
                        <div className="text-center mt-4">
                            <input type="checkbox" className= "shadow-lg" />
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="text-center">
                            <label >IOPS</label>
                        </div>
                        <div className="mt-4 text-center">
                            <label>600</label>
                            </div>
                    </div>
                    <div className="col-2">
                        <div className="text-center">
                            <label >Backup Required</label>
                        </div>
                        <div className="text-center mt-4">
                            <input type="checkbox" className="checked-primary shadow-lg" />
                        </div>
                    </div>
                    <div className="col-2 " style={{ fontSize: "14px" }} >
                        <div className="text-center">
                            <label >Remarks</label>
                        </div>
                        <div className="mt-2 ">
                            <input className="form-control" type="text" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }

    const onclick = () => {
        setCount([...count, "1"])
    }
    

    return (
        <div>
        <div className="card shadow-lg m-4">
            <div className="card-body" style={{fontSize:"12px"}}>
                <div className="row col-12">

                    <div className="col-2">
                        <div className="text-center">
                            <label >Type</label>
                        </div>
                        <div className="form-group mt-2">
                            <select className="form-control" id="dropdown">
                                <option>Magnetic</option>
                                <option>SSD</option>
                            </select>
                        </div>

                    </div>
                    <div className="col-1">
                        <div className="text-center">
                            <label >Volume</label>
                        </div>
                        <div className="mt-4 text-center">
                            <label>Root</label>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="text-center">
                            <label >Capacity(GB)</label>
                        </div>
                        <div className="mt-2 ">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="text-center">
                            <label >Encryption</label>
                        </div>
                        <div className="text-center mt-4">
                            <input type="checkbox" className= "shadow-lg" />
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="text-center">
                            <label >IOPS</label>
                        </div>
                        <div className="mt-4 text-center">
                            <label>600</label>
                            </div>
                    </div>
                    <div className="col-2">
                        <div className="text-center">
                            <label >Backup Required</label>
                        </div>
                        <div className="text-center mt-4">
                            <input type="checkbox" className="checked-primary shadow-lg" />
                        </div>
                    </div>
                    <div className="col-2 " style={{ fontSize: "14px" }} >
                        <div className="text-center">
                            <label >Remarks</label>
                        </div>
                        <div className="mt-2 ">
                            <input className="form-control" type="text" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        {
            count.map(ele => <StorageNet />)
        }
        <button  className="btn btn-primary mt-suto mx-suto" onClick={onclick}>Add Volume</button>
        <div className="row col-8 m-4 p-2">
        <label htmlFor="customRange1" className="form-range form-label">Network Bandwidth Configuration</label>
<input type="range" className="form-range form-control-range form-range-track-cursor" id="customRange1" min="512" max="2048" step="1"  />

        </div>
        </div>

    )
}

export default StorageAndNetwork;