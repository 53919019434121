import { useState } from "react";


const StorageNet = () => {

    const [type, setType] = useState("")
    const onchange = (e: any) => {
        console.log(e);
        setType(e)
    }

    return <div className="card shadow-lg m-4">
    <div className="card-body" style={{fontSize:"12px"}}>
        <div className="row col-12">

            <div className="col-2">
                <div className="text-center">
                    <label >Type</label>
                </div>
                <div className="form-group mt-2">
                    <select className="form-control" id="dropdown" onChange={onchange}>
                        <option>Magnetic</option>
                        <option>SSD</option>
                    </select>
                </div>

            </div>
            <div className="col-1">
                <div className="text-center">
                    <label >Volume</label>
                </div>
                <div className="mt-4 text-center">
                    <label>Ext</label>
                </div>
            </div>
            <div className="col-2">
                <div className="text-center">
                    <label >Capacity(GB)</label>
                </div>
                <div className="mt-2 ">
                    <input className="form-control" type="text" />
                </div>
            </div>
            <div className="col-2">
                <div className="text-center">
                    <label >Encryption</label>
                </div>
                <div className="text-center mt-4">
                    <input type="checkbox" className= "shadow-lg" />
                </div>
            </div>
            <div className="col-1">
                <div className="text-center">
                    <label >IOPS</label>
                </div>
                <div className="mt-4 text-center">
                    <label>{type === 'Magnetic' ? 100: 600}</label>
                    </div>
            </div>
            <div className="col-2">
                <div className="text-center">
                    <label >Backup Required</label>
                </div>
                <div className="text-center mt-4">
                    <input type="checkbox" className="checked-primary shadow-lg" />
                </div>
            </div>
            <div className="col-2 " style={{ fontSize: "14px" }} >
                <div className="text-center">
                    <label >Remarks</label>
                </div>
                <div className="mt-2 ">
                    <input className="form-control" type="text" />
                </div>
            </div>

        </div>
    </div>
</div>
}

export default StorageNet;