import React from "react";




const PricingCard = (props:any) => {
    const {selected, configType} = props;
    let sum =0 ;
    return <React.Fragment>
        <div className="card float-right shadow-lg">
            <h4>Cost Estimation</h4>
        
        <div>
            {selected.map((ele: any) => { // choose image value
            sum += ele.price;
                return <div className="row col-12">
                    <div className="col-6">{`${ele.name} ${ele.config}`}</div>
                    <div className="col-6">{ele.price}</div>
                </div>
            })}
            {
                Object.keys(configType).map(ele => { // for instance type
                    sum += configType[ele];
                   return <div className="row col-12">
                    <div className="col-6">{`${ele}`}</div>
                    <div className="col-6">{`${configType[ele]}`}</div>
                </div>
            })
            }
        </div>
        <div className="row col-12 pt-1">
            <div className="col-6"><b>Total</b></div>
            <div className="col-6"><b>{sum}</b></div>
        </div>
        </div>
    </React.Fragment>
}
export default PricingCard;