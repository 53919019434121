import React from "react";
import { NewLineKind } from "typescript";

interface IProps { }
interface IState { }

export class ChooseImage extends React.Component<IProps, IState>{

    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="float-start">
                        <h2 >Choose Image</h2>
                    </div>
                    <div className="dropdown float-end">
                        {/* <button className="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" id="regiondropdown" aria-expanded="false">
                            Region
                        </button>
                        <ul className="dropdown-menu row col-8" aria-labelledby="regiondropdown">
                            <li><a className="dropdown-item" href="#">US East</a></li>
                            <li><a className="dropdown-item" href="#">Asia Pacific - Mumbai</a></li>
                            <li><a className="dropdown-item" href="#">US West</a></li>
                            <option className=" form dropdown-item" aria-labelledby="regiondropdown" id="drp" >India</option>
                                <option>Singapore</option>
                        </ul> */}
                        {/* <div className="form-group mt-2"> */}
                            <select className="form-control dropdown shadow-lg input-sm float-right float-end col-xs-12 col-sm-6 col-md-4 col-lg-4 text-dark" id="dropdown">
                            <option>Region</option>

                            <option>India</option>
                                <option>US East 1</option>
                                <option>US West 1</option>
                                <option>US East 2</option>
                            </select>
                        {/* </div> */}
</div>
</div>
            </React.Fragment>
        )
    }
}