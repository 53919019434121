import React, { useState } from "react";
import RulesAndProtocols from "./RulesAndProtocols";
import StorageNet from "./StorageNet";


const ChooseSecurity = () => {
    const result : string[] = [];
    const [count,setCount] = useState (result);



const onclick = () => {
    setCount([...count, "1"])
}
    return (
        <div className="row col-12 m-2 p-2">

            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                <label className="form-check-label" htmlFor="flexRadioDefault1" style={{ fontSize: "14px" }}>
                    Create a new security group
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ fontSize: "14px" }}>
                    Select an existing security group
                </label>
            </div>

            <div>
                <div className=" m-2 col-3">
                        <label > <b> New Security Group </b> </label>
                        <input className="form-control m-2" type="text" />
                </div>
                {
            count.map(e => <RulesAndProtocols />)
        }
            <button  className="btn btn-primary" onClick={onclick}>Add Rule</button>

            </div>
           
        </div>
    )
}


export default ChooseSecurity;